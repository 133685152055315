<template>
  <div>
    <div v-if="loading || !formLoaded">
      <span class="spinner"></span>
    </div>
    <div v-if="!loading && formLoaded">
      <b-form
        @submit.stop.prevent="onSubmit"
        :class="{
          'p-3 box-shadow2 rounded': !isDarkMode,
          'dark-div p-3 box-shadow2 rounded': isDarkMode
        }"
      >
        <b-form-group id="group-name" label-for="input-name">
          <template v-slot:label>
            Promo Id
            <span class="text-danger">*</span>
          </template>

          <b-form-input
            :class="{
              'box-shadow3 bg-light text-dark': !isDarkMode,
              'bg-dark text-light placeholder-light': isDarkMode
            }"
            id="input-name"
            type="text"
            v-model="form.promoId"
            placeholder="Enter Promo Id"
          ></b-form-input>

          <b-form-invalid-feedback id="input-name-invalid">Please enter Promo Id.</b-form-invalid-feedback>
        </b-form-group>

        <b-form-group id="group-name" label-for="input-name">
          <template v-slot:label>
            Message
            <span class="text-danger">*</span>
          </template>

          <b-form-input
            :class="{
              'box-shadow3 bg-light text-dark': !isDarkMode,
              'bg-dark text-light placeholder-light': isDarkMode
            }"
            id="input-name"
            type="text"
            v-model="form.message"
            placeholder="Enter Message"
          ></b-form-input>

          <b-form-invalid-feedback id="input-name-invalid">Please enter Message.</b-form-invalid-feedback>
        </b-form-group>

        <b-form-group>
          <b-row class="mt-2">
            <b-col class="text-right">
              <b-button
                type="submit"
                size="sm"
                :variant="isDarkMode ? 'outline-success' : 'success'"
                :disabled="loading"
              >
                <span class="spinner spinner-white" v-if="loading"></span>
                <font-awesome-icon :icon="['fas', 'save']" class="mr-1" />Save
              </b-button>
              <b-button
                class="ml-2"
                size="sm"
                :variant="isDarkMode ? 'outline-warning' : 'warning'"
                :to="{ path: `/promo-code` }"
              >
                <font-awesome-icon :icon="['fas', 'long-arrow-alt-left']" class="mr-1" />Back to list
              </b-button>
            </b-col>
          </b-row>
        </b-form-group>
      </b-form>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Multiselect from 'vue-multiselect';

export default {
  name: 'PromoCodeFormBox',
  components: {
    Multiselect
  },
  props: {
    listUrl: String,
    formType: String,
    feedbackType: String
  },
  metaInfo() {
    return {
      meta: [
        {
          name: 'description',
          content: this.metaDescription
        }
      ]
    };
  },
  data() {
    return {
      title: '',
      formLoaded: true,
      form: {
        promoId: '',
        message: ''
      }
    };
  },
  computed: {
    metaDescription() {
      return this.formType === 'new' ? 'Add new Promo Code' : 'Update Promo Code';
    },
    ...mapState('promoCode', ['loading', 'promo']),
    ...mapState('auth', ['user']),
    ...mapState('darkMode', ['isDarkMode'])
  },
  methods: {
    ...mapActions('promoCode', ['list', 'postOne', 'getOne', 'patchOne']),

    onSubmit() {
      const promoCode = {
        promoId: this.form.promoId,
        message: this.form.message
      };
      if (this.formType === 'new') {
        this.$emit('add', { promoCode });
      } else {
        this.$emit('edit', { promoCode });
      }
      return false;
    }
  },
  watch: {
    promo(newValue) {
      this.form.promoId = newValue.promoId;
      this.form.message = newValue.message;
      this.formLoaded = true;
    }
  }
};
</script>
<style src="vue-search-select/dist/VueSearchSelect.css"></style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style>
.calender {
  display: flex;
  width: auto;
  margin-left: -9px;
  margin-bottom: 0.7rem;
}
</style>
