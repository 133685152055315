var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loading || !_vm.formLoaded)?_c('div',[_c('span',{staticClass:"spinner"})]):_vm._e(),(!_vm.loading && _vm.formLoaded)?_c('div',[_c('b-form',{class:{
        'p-3 box-shadow2 rounded': !_vm.isDarkMode,
        'dark-div p-3 box-shadow2 rounded': _vm.isDarkMode
      },on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"id":"group-name","label-for":"input-name"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Promo Id "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,false,1161412289)},[_c('b-form-input',{class:{
            'box-shadow3 bg-light text-dark': !_vm.isDarkMode,
            'bg-dark text-light placeholder-light': _vm.isDarkMode
          },attrs:{"id":"input-name","type":"text","placeholder":"Enter Promo Id"},model:{value:(_vm.form.promoId),callback:function ($$v) {_vm.$set(_vm.form, "promoId", $$v)},expression:"form.promoId"}}),_c('b-form-invalid-feedback',{attrs:{"id":"input-name-invalid"}},[_vm._v("Please enter Promo Id.")])],1),_c('b-form-group',{attrs:{"id":"group-name","label-for":"input-name"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Message "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,false,196873832)},[_c('b-form-input',{class:{
            'box-shadow3 bg-light text-dark': !_vm.isDarkMode,
            'bg-dark text-light placeholder-light': _vm.isDarkMode
          },attrs:{"id":"input-name","type":"text","placeholder":"Enter Message"},model:{value:(_vm.form.message),callback:function ($$v) {_vm.$set(_vm.form, "message", $$v)},expression:"form.message"}}),_c('b-form-invalid-feedback',{attrs:{"id":"input-name-invalid"}},[_vm._v("Please enter Message.")])],1),_c('b-form-group',[_c('b-row',{staticClass:"mt-2"},[_c('b-col',{staticClass:"text-right"},[_c('b-button',{attrs:{"type":"submit","size":"sm","variant":_vm.isDarkMode ? 'outline-success' : 'success',"disabled":_vm.loading}},[(_vm.loading)?_c('span',{staticClass:"spinner spinner-white"}):_vm._e(),_c('font-awesome-icon',{staticClass:"mr-1",attrs:{"icon":['fas', 'save']}}),_vm._v("Save ")],1),_c('b-button',{staticClass:"ml-2",attrs:{"size":"sm","variant":_vm.isDarkMode ? 'outline-warning' : 'warning',"to":{ path: "/promo-code" }}},[_c('font-awesome-icon',{staticClass:"mr-1",attrs:{"icon":['fas', 'long-arrow-alt-left']}}),_vm._v("Back to list ")],1)],1)],1)],1)],1)],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }